:root {
  --primary: #3f6ad8;
  --secondary: #eef5f9;
  --blue: #2576d2;
  --gray: #e9ecef;
  --gray-dark: #495057;

  --bitys-green: #7fc34d;
  --bitys-blue: #287900;

  --bg-color: #f1f4f6;
  --bg-logo: #1e293b;
  --bg-menu-active: #343a40;
  --bg-header: rgba(250, 251, 252, 0.1);
  --bg-menu: #1e293b;
  --color-menu: #fff;
  --color-menu-dest: #343a40;
}

/* Input checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: var(--blue);
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ql-editor {
  min-height: 120px !important;
}
.ql-snow .ql-tooltip {
  left: 0 !important;
}
.hidden {
  display: none;
}
/* .ReactCrop__crop-selection {
  
} */

.cropProfile :nth-child(2) {
  border-radius: 50%;
}

/* Gravador de áudio */
._1ceqH ._1lB9c,
._1Yplu {
  display: none !important;
}
._2fG9h {
  padding: 0 !important;
}
._eV_dK {
  text-align: center !important;
}
.basic-multi-select {
  width: 100%;
}

@media (min-width: 992px) {
  .heading-icon {
    color: var(--color-menu) !important;
  }
}
@media (max-width: 992px) {
  .heading-icon {
    color: var(--bg-color) !important;
  }
}

.heading-selected {
  background: var(--bg-color) !important;
}
